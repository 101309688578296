.addcartmeatsize{
    width: 100%;
}

.fullborderblack {
    border: 1px solid #868790;
}

.fullborderblack.leftnone{
    border-left: none;
}

.meatweight{
    background: #D9D9D9;
    border-radius: 4px;
}
button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

/*--------------------------*/
.qty-container{
  display: flex;
  align-items: center;

}
.qty-container .input-qty{
  text-align: center;
  padding: 6px 10px;
  /* border: 1px solid #9b9b9b; */
  border: none;
  background-color: #E05A67;
  max-width: 80px;
  color: #fff;
  height: 33px;
}
.qty-container .qty-btn-minus,
.qty-container .qty-btn-plus{
  /* border: 1px solid #9b9b9b; */
  background-color: #E05A67;
  color: #fff;
  padding: 10px 13px;
  font-size: 10px;
  height: 33px;
  width: 38px;
  transition: 0.3s;
}
.qty-container .qty-btn-plus{
  margin-left: -1px;
}
.qty-container .qty-btn-minus{
  margin-right: -1px;
}



/*---------------------------*/
.btn-cornered,
.input-cornered{
  border-radius: 4px;
}
.btn-rounded{
  border-radius: 50%;
}
.input-rounded{
  border-radius: 50px;
}


.bestdealcarousal.addcart .slick-track .slick-slide:nth-of-type(odd) .item
{
    background: linear-gradient(180deg, #E05A67 0%, rgba(224, 90, 103, 0.16) 100%);
}

.reviewaddcart .slick-slider {
  margin-bottom: 0px;
}