
#profile-logout-popup {
    right: 0;
    top: 38px;
    z-index: 3;
    display: none;
}

.bb-dashed{
    border-bottom: 1px dashed rgb(207 204 204);
}

.toolbar.positionsticky{
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(224, 90, 103, 0.17);

}

@media(max-width:576px) {
    .toolbar.positionsticky {
        z-index: 2;
}


}