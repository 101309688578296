
.hamburger-btn{
    left: 0;
  }

  .div1{
    width: 25%;
  }

  .banner-img .lh-33 {
    line-height: 45px;
}

@media (max-width: 576px){
.catfish .grid-container {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 20px;
}
.catfish .topsellingfish-items {
    height: 90px;
    width: 100%;
}

.div1{
    top: 0;
    left: 0;
    position: fixed;
    transition: all 0.25s ease;
    z-index: 10;
    transform: translateX(-100%);
    width: 280px;
    overflow-y: auto;
    max-height: 100vh;
}
.kilobg {
    padding: 0px 3px;
}

.div1.open{
    transform: translateX(0px);
  }

 .banner-img {
    background-image: url(http://localhost:3000/static/media/Artboard.9e3ad0e….jpg);
    background-size: cover;
    height: auto;
    background-position-x: -130px;
}

.banner-img .lh-33 {
    line-height: 22px;
}
.banner-img .f-40 {
    font-size: 18px;
}

.banner-img .f-sm{
    font-size: 12px;
}

.banner-img button {
    width: 113px;
    height: 32px;
    font-size: 13px;
}
}