.logosize{
    width: 100px;
}

.f-links a{
    text-decoration: none;
    color: #0B1426;
    font-size: 14px;
    font-weight: 500;
}

.followus .socialmedia {
    background: #E05A67;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease;
}

.followus a{
    color: #fff;
    font-size: 15px;
    display: flex;
    text-decoration: none;
}

.followus .socialmedia:hover{
    transform: translateY(-5px);
}

.followus a:hover{
    /* transform: translateY(-10px); */
    color: #fff;
} 

.f-address span{
    color: #E05A67;

}

.f-input{
    background: #FFFFFF;
border-radius: 5px;
border: none;
height: 35px;
padding: 0px 5px;
font-size: 12px;
}

.f-sendemail .send-btn{
    width: 45px;
    height: 100%;
}


.followus input {
    height: 35px;
    border: none;
    padding-left: 15px;
    padding-right: 90px;
    font-size: 15px;
}