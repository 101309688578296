@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");



body {
  margin: 0;
  font-family: 'DM Sans';font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9 !important;
}

code {
  font-family: 'DM Sans';font-size: 22px;

}

button{
  border: none;
  background-color: transparent;
}

input{
  outline: none;
}

a{
  text-decoration: none;
}

a:hover{
  text-decoration: none;
  color: inherit;
}


input:focus,
textarea:focus{
  border: 2px solid #a42f35;
}

.h-100vh{
  height: 100vh;
}

.w-50-resp-mob{
  width: 50%;
}

.w-75-resp-mob{
  width: 75%;
}

.w-20-resp-mob{
  width: 20%;
}


.w-25-resp-mob{
  width: 25%;
}

.w-40-resp-mob,.w-40-resp-tab{
  width: 40%;
}

.w-60-resp-mob,.w-60-resp-tab{
  width: 60%;
}

.w-50-resp-tab{
  width: 50%;
}

.w-100div3{
  width: calc(100% / 3);
}


/* ************ COMMON CLRS ************* */

.black-clr{
  color: #0B1426;
}

.clr-202020{
  color: #202020;
}

.clr-E7E7E7{
  color: #E7E7E7;
}

.white-clr{
  color: #fff;
}

.clr-565B67{
  color: #565B67;
}

.clr-898989{
  color: #898989;
}

.clr-33363F{
  color: #33363F;
}

.clr-868790{
  color: #868790;

}


.brick-clr{
  color:  #97262c;
}

.lightbrick{
  color: #E05A67;
}

.lightgrey-clr{
  color: #C7C7C7;
}

.transparent{
  color: transparent;
}

/* ********** COMMON BG CLRS *********** */


.bg-white{
  background-color: #fff;
}

.bg-transparent{
  background-color: transparent;
}

.bg-darknavy{
  background-color: #0B1426;
}


.bg-d5d5d5{
  background: #d5d5d5;

}

.bg-FOFOFO{
  background-color: #f0f0f0;
}

.bg-f8f8f8{
  background-color: #F8F8F8;
}

.bg-lightbrick{
  background-color: #E05A67;
}

.bg-F7EBEC
{
  background: #F7EBEC;

}

.bg-F9F9F9{
  background-color: #F9F9F9;
}
.bg-extralightbrick{
background: rgba(224, 90, 103, 0.08);
}

.bg-gradlightbrick{
background: linear-gradient(180deg, rgba(224, 90, 103, 0.73) 0%, rgba(224, 90, 103, 0.7) 50.83%, #E05A67 100%);
}
/* ********** COMMON BTNS ************* */
.h-33px{
  height: 33px;
}
.brick-btn{
  background-color: #97262c;
  color: #fff;
  border-radius: 5px;
  border: none;
  height: 33px;
}

.brick-btn:hover{
  background-color: #fff;
  color: #97262c;
  border: 2px solid #97262c;
  font-weight: 600;
}

.lightbrick-btn{
  background-color: #E05A67;
  color: #fff;
  border-radius: 5px;
  border: none;
  height: 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.lightbrick-btn-outline{
  background-color: #fff;
  border: 2px solid #E05A67;
  color: #E05A67;
  border-radius: 5px;
  height: 33px;
  font-weight: 600;
}


.lightbrick-btn:hover{
  background-color: #fff;
  color: #E05A67;
  border: 2px solid #E05A67;
  font-weight: 600;
  box-shadow: none;
}

.round-lightbrick-btn-outline{
  background-color: #fff;
  border: 1px solid #E05A67;
  color: #E05A67;
  border-radius: 50px;
  height: 33px;
  font-weight: 400;
}



.round-lightbrick-btn-outline:hover{
  background-color: #E05A67;
  color: #fff;

}

.plain-lightbrick-btn{
  background: rgba(224, 90, 103, 0.13);
  color: #97262c;
  border-radius: 5px;
  border: none;
  height: 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

}

.plain-lightbrick-btn:hover{
  background-color: #fff;
  color: #E05A67;
  border: 2px solid #E05A67;
  font-weight: 600;
  box-shadow: none;
}



.white-btn{
  background-color: #F0F0F0;
  color: #0B1426;
  border-radius: 5px;
  border: none;
  height: 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.white-btn:hover{
  background-color: #fff;
  color: #0B1426;
  border: 2px solid #0B1426;
  font-weight: 600;
  box-shadow: none;
}

.black-btn{
  background-color: #191B2D;
  color: #fff;
  border-radius: 5px;
  border: none;
  height: 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.black-btn:hover{
  border: 2px solid #191B2D;
  font-weight: 600;
  color: #191B2D;
  background-color: transparent;

}
/* ********** COMMON WIDTHS ************* */



/* ********** COMMON RADIUS ************* */

.radius-11{
  border-radius: 11px;
}

.radius-7{
  border-radius: 7px;
}

.radius-35{
  border-radius: 35px;
}

.radius-50{
  border-radius: 50%;
}


/* ********** COMMON FONTS ************* */

.f-8{
  font-size: 8px;
}

.f-xxs{
  font-size: 11px;
}

.f-12{
  font-size: 12px;
}

.f-13{
  font-size: 13px;
}

.f-xs{
  font-size: 14px;
}
.f-15{
  font-size: 15px;
}


.f-sm{
  font-size: 16px;
}

.f-md{
  font-size: 18px;
}

.f-lg{
  font-size: 20px;
}

.f-22{
  font-size: 22px;
}

.f-xl{
  font-size: 25px;
}

.f-30{
  font-size: 30px;
}

.f-xxl{
  font-size: 35px;
}

.f-40 {
  font-size: 40px;
}

.f-70{
  font-size: 70px;

}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

/* ********** POSITIONS ************* */


.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

.pe-6 {
  padding-right: 4rem!important;
}

.position-middle-r{
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
}

.r-0{
  right: 0;
}

.l-13{
  left: 13px;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ********** INP F5F5F5 ************* */


.inp-F5F5F5{
  background-color: #F5F5F5;
  border: none;
  outline: none;
}

.inp-F5F5F5-outline{
  border: 2px solid #F5F5F5;
  border-radius: 7px;
}

.inp-F0F0F0{
  background-color: #F0F0F0;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 13px;
}

.inp-EFEFEF{
  background-color: #ebebeb;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 13px;
  height: 30px;
}


.inp-FFF{
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 13px;
  height: 33px;
  padding: 0px 10px;

}

.inp-F5F5F5:focus{
  border: 2px solid #9d282e;
}

.inp-F5F5F5::-ms-reveal {
  display: none;
}

.inp-D9D9D9{
  border: 1px solid #D9D9D9;
  border-radius: 7px;
  height: 40px;
  padding: 0 10px;
}


/* ********** BOX SHADOW ************* */

.box-shadow{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);;
}

.underline-none{
  text-decoration: none;
}

.underline{
  text-decoration: underline;
}

.zindex1{
  z-index: 1;
}


.h-100{
  height: 100%;

}

.bb-dashed{
  border-bottom: 1px dashed rgba(101, 100, 100, 0.927);
}

.status{
  border-radius: 5px;
  color: white;
  width: 105px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .status.red{
    background: #E05A67;

}

.status.grey{
background: #1C2D51;
}

.status.green{
    background: #98da63;
}

.status.yellow{
  background: #e5d702;
  ;
}

.status.orange{
  background: #e07927e8;
  ;
} 
.zoomoutonhover {
  transition: 0.3s all ease;
}



.zoomoutonhover:hover {
  transform: scale(0.9);
}

.apexcharts-toolbar { 
 
  display: none !important;
}

.rounduser{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

::-webkit-scrollbar:vertical {
  display: none;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0B1426; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0B1426; 
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#0B1426;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #0B1426;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #0B1426;
}

@media (max-width:991px){
  .w-50-resp-tab, .w-60-resp-tab, .w-40-resp-tab{
    width: 100%;
  }
}

@media (max-width:576px){
  .w-50-resp-mob{
    width: 100%;
  }

  .w-60-resp-mob, .w-75-resp-mob, .w-25-resp-mob{
    width: 100%;
  }

  .w-40-resp-mob, .w-20-resp-mob{
    width: 100%;
  }
  .w-100div3{
    width: 100%;
  }
}