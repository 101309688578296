.w-100-resp-40-mob{
    width: 100%;
}

@media (max-width: 576px) {
    .w-100-resp-40-mob{
        width: 40%;
    }
  }
  
