.TaC-checkbox-size{
    width: 15px;
    height: 15px;
    accent-color: #0B1426;

}


.w-30{
    width: 30%;
}



.sm-btns:hover{
    
    transform: translateY(-5px);
    transition: all 0.10s ease;

}

.loginlink-anc:hover{
    text-decoration: none;
    color: #97262c;
}

@media (max-width: 991px) {
    .w-30{
        width: 50%;
    }
}