:host {
  z-index: 1;
}
.w-30 {
  width: 30%;
}

.sm-btns:hover {
  transform: translateY(-5px);
  transition: all 0.1s ease;
}

.forgetpass-anc:hover {
  text-decoration: underline;
  color: #0b1426;
}

@media (max-width: 991px) {
  .w-30 {
    width: 50%;
  }
}
