.aboutusimgsize{
    width: 360px;
}


.lh-33 {
    line-height: 33px;
}

.missionvission img{
    width: 80px;
}

.ousrservices .item{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 70px;
    position: relative;
    width: calc(100% / 3);
    text-align: center;
    height: 270px;
    background-color: #fff;
}

.ousrservices .item:hover{
    background: linear-gradient(180deg, rgba(224, 90, 103, 0.07) 0%, rgba(224, 90, 103, 0.77) 100%);
}

.ousrservices img{
    width: 70px;
}

.ousrservices .img-bg{
    background: linear-gradient(180deg, rgba(224, 90, 103, 0.77) 0%, rgba(224, 90, 103, 0.43) 100%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -47px;
    transform: translateX(-50%);
    left: 50%;
}

.mb-100{
    margin-bottom: 100px;
}

.banner-img{
    background-image: url('../../images/Aboutus/frontimg.png');
    width: 100%;
    padding-left: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 562px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
/* 
.keepintouchimg {
    width: 400px;
} */

.banner-img button{
    border-radius: 23px;
    width: 155px;
    height: 40px;
}

@media (max-width: 576px) {
.ousrservices .item {
    padding: 70px;
    width: 100%;
    margin-bottom: 77px;
}

.keepintouchimg {
    width: 290px;
}

.banner-img {
    padding-left: 0px;
    padding: 20px;
}
}