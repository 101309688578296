
.accordion {
    border: none;
} 

.accordion__button {
    background-color:  rgba(224, 90, 103, 0.07) !important;
    color: #2c2c2c !important;
    font-size: 14px;
    font-weight: 500;
}

.accordion__button:before {
    height: 7px !important;
    width: 7px !important;
    margin-right: 24px;
}

.main {
    font-size: 120%;
    color: red;
  }

  #faq{
    display: block;
  }

  #contactus{
    display: none;
  }