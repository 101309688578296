.w-50-resp{
    width: 50%;
}
.w-50-lg-auto{
    width: auto;
}

.createsales .box {
    min-width: 105px;
    height: 98px;
    margin: 5px;
}

.createsales .js--image-preview {
    height: 74px;
    width: 100%;

}


.createsales .upload-options {
    height: 24px;
}

.createsales .upload-options label::after {
    font-size: 1.7rem;
    top: calc(50% - 1.2rem);
    left: calc(50% - 0.8rem);
}

@media (max-width: 576px) {
    .w-50-resp {
      width: 100%;
    }

    .w-50-lg-auto{
        width: 50%;
    }
  }