

.w-7{
    width: 7%;
}


.w-93{
    width: 93%;
}

.w-10{
    width: 10%;
}

.w-20{
    width: 20%;
}

.w-25{
    width: 25%;
}

.w-30{
    width: 30%;
}

.w-60{
    width: 60%;
}

.w-65{
    width: 65%;
}

.w-40{
    width: 40%;
}

.w-80{
    width: 80%;
}

.search-item{
    background: #F0F0F0;
    border: 1px solid #D2D2D2;
border-radius: 5px;
}

.transparent-inp{
    border: transparent;
    background-color: transparent;
    color: #292a2d;
    font-weight: 600;
    padding: 0px 30px 0px 40px;

}

.customer .nav-search-inp {
    height: 40px;
    width: 275px;
    border: 1px solid #d5cccc;
    border-radius: 5px;
    padding: 5px 15px 5px 34px;
    font-size: 14px;
    color: #131519;
    background: #F0F0F0;
    font-weight: 500;
}

.customer .search-i-position i {
    color: #101010d6;
}
.add-prod-link{
    padding: 6px 0px;
    text-decoration: none;
}

.add-prod-link:hover{
    text-decoration: none;

}

.transparent-inp:focus{
    border: none;
}

.product-table img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.slider{
    background: #D9D9D9;
    border-radius: 6px;
    color: #33363F;
}

.w-90{
    width: 90%;
}

.search-item-btn{
    border-left: 2px solid #cacaca;
    padding-left: 10px;
}

.instock{
    background: rgba(56, 226, 93, 0.14);
    border-radius: 3px;
    padding: 3px 10px;
}

.outstock{
    background: rgba(224, 90, 103, 0.38);
    border-radius: 3px;
    padding: 3px 10px;
}

.preorder{
    background: #CCD2E3;
    border-radius: 3px;
    padding: 3px 10px;
}


.customer .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .customer .table td,
  .customer .table th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }
  
  .customer .table th {
    background-color: #92c9e6;
    color: #ffffff;
  }
  
  .customer .table tbody tr:nth-child(even) {
    background-color: #d1e8f5;
  }
  
  /*responsive*/
  
  @media (max-width: 500px) {
    .customer .table thead {
      display: none;
    }
  
    .customer .table,
    .customer .table tbody,
    .customer .table tr,
    .customer .table td {
      display: block;
      width: 100%;
    }
    .customer .table tr {
      margin-bottom: 15px;
    }
    .customer .table td {
      padding-left: 50%;
      text-align: left;
      position: relative;
    }
    .customer .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  