.sharedusers{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.w-15{
    width: 15%;
}

.shared-circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    background: linear-gradient(90deg, rgba(224, 90, 103, 0.84) 50%, #E05A67 108.62%);
    color: #fff;
    justify-content: center;
    align-items: center;
}

.shared-circle:hover {
    background: #fff;
    border: 2px solid #E05A67;
    color: #E05A67;
    font-weight: 600;
}

.date-inp {
    background: #F0F0F0;
    border: none;
    border-radius: 7px;
    height: 39px;
    width: 145px;
}

.alltype{
    background: #F0F0F0;
    height: 39px;
    width: 100px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.filemanager .search-inp{
    border-radius: 7px;
    height: 39px;
    border: none;
    background: #F0F0F0;
    width: 250px;
    padding: 0px 30px 0px 40px;
}

.fileimgs {
    width: 40px;
}

.filemanager .search-i-position i {
    color: #333333ea;
}

.filetypeborder{
    border-radius: 10px;
    border: 1px solid #DFEDF2;
}

#alltypedropdown{
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
    display: none;
}

.sharedpersonimg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
}

.border-bottom-dashed{
    border-bottom: 1px dashed #c5c5c5;
}

.filemanager .modal.show .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
}

.filemanager .modal-content {
    width: 75%;
}