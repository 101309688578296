.view-invoice-proimg{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
}

.gradientline{
    background: linear-gradient(90deg, #E05A67 0%, #97262C 18.13%, #0B1426 43.19%, #AAE07E 83.64%);
border-radius: 14px;
height: 4px;
margin-top: 15px;
margin-bottom: 35px;
}