

.pad-lr-95 {
    padding-right: 95px;
    padding-left: 95px;
}
.topsellingfish{
    width: 96%;
    height: 250px;
    max-width: 700px;
    border-radius: 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-template-rows: 1fr;
    gap: 15px;
  }
  .grid-item {
    /* padding: 20px; */
    /* font-size: 30px; */
    text-align: center;
    justify-self: start;
    transition: top ease 0.5s;
    position: relative;
  top: 0;

  }

  
.grid-item:hover {
    top: -10px;
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.text-over-img{
    background: rgb(0 0 0 / 43%);
    color: #fff;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    bottom: 0;
    width: 96%;
    text-align: center;
}

.wid100div4{
    width: calc(100% / 4);
}

.topsellingfish-items{
    width: 195px;
    height: 118px;
    border-radius: 7px;
}

.heartposition {
    top: 3px;
    right: 11px;
    color: white;
}

.price-outline {
    background: #e6e6e678;
    height: 33px;
    border-radius: 23px;
    border: 1px solid #cecece;
}


@media (max-width:576px){
    .pad-lr-95 {
        padding-right: 10px;
        padding-left: 10px;
    }
.grid-container {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;

}
.topsellingfish {
    height: 192px;

}

.topsellingfish-items {
    height: 160px;
    width: 100%;
}
}