.adminsettings .w-40-resp{
    width: 40%;
}
.adminsettings .w-50-resp{
    width: 50%;
}

.adminsettings .w-60-resp{
    width: 60%;
}

.adminsettings .w-30-resp{
    width: 30%;
}

.adminsettings .box {
    min-width: 200px;
    height: 200px;
    border-radius: 50%;
}

.adminsettings .js--image-preview {
    height: 170px;
}

.adminsettings .upload-options label::after {
    font-size: 1.7rem;
    top: calc(50% - 1.5rem);
    left: calc(50% - 0.7rem);
}


.adminsettings .toggle-btn {
	display : none;
}
.adminsettings .toggle-btn-label {
	position: relative;
	cursor: pointer;
	display :block;
	width: 40px;
	height: 17px;
	background: #97262C;
	border-radius: 50px;
	transition: 300ms linear;
}

.adminsettings .toggle-btn-label:before {
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
	content: "";
	display: block;
    width: 12px;
    height: 12px;
	background: #D9D9D9;
	border-radius: 50%;
	transition: left 300ms linear;
}

.adminsettings .toggle-btn:checked + label {
	background: #C4C4C4;
}

.adminsettings .toggle-btn:checked + label:before {
	left: 25px;
    background: #818080;
}

.adminsettings label {
    margin-bottom: 0 !important;
}

@media (max-width:576px){
    .adminsettings .w-40-resp{
        width: 100%;
    }
    .adminsettings .w-50-resp{
        width: 100%;
    }
    
    .adminsettings .w-60-resp{
        width: 100%;
    }
    
    .adminsettings .w-30-resp{
        width: 100%;
    }
}