.errorimgwid{
    width: 450px;
}

@media (max-width:576px){
    .errorimgwid{
        width: 200px;
    }
    

}