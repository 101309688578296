.coupon .status{
border-radius: 5px;
color: white;
width: 105px;
height: 25px;
display: flex;
align-items: center;
justify-content: center;
}

.coupon .status.completed{
    background: #E05A67;

}

.coupon .status.pending{
background: #1C2D51;
}

.coupon .status.invoicesent{
    background: #AAE07E;
}

