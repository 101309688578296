@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');

.ff-carousaly {
  font-family: 'Abril Fatface';
}

.lh-72 {
  line-height: 72px;

}


.bestdealcarousal .item,
.weeklyspecial .item {
  width: fit-content;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: auto;
}

.bestdealcarousal .item:hover {
  background-color: #d6030315;
}

.h-27 {
  height: 27px;
}

.kilobg {
  border: 2px solid #E05A67;
  color: rgb(29, 28, 43);
  border-radius: 5px;
  background-color: transparent;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-weight: 700;
  padding: 0px 5px;
}

.wid100div4 {
  width: calc(100 / 4);
}

.carousel-caption {
  right: 46% !important;
  bottom: 6.25rem !important;
  left: 13% !important;
  text-align: left !important;
}

.carousel-inner {
  border-radius: 21px !important;
}

.onhovergradbrick {
  transition: all 0.1s ease;
}

.onhovergradbrick:hover {
  transform: translateY(-5px);
  background: linear-gradient(180deg, rgba(224, 90, 103, 0.148) 0%, #f3748028 100%);
}

.whychooseus {
  margin-top: 100px;
}

.whychooseuspositionimg {
  right: 70px;
  top: -107px;

}

.whychooseusimg {
  width: 307px;
}


.pl-110 {
  padding-left: 110px;
}

.bg-keepintouch {
  background-image: url('../../images/Userhome/keepintouch.svg');
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.bg-keepintouch input {
  height: 45px;
  border: none;
  padding-left: 15px;
  padding-right: 90px;
  font-size: 15px;
}

.bg-keepintouch button {
  right: 0px;
  height: 100%;
  padding: 0px 12px;
  border-radius: 9px;
}

.bg-latestdelivery {
  background-image: url('../../images/Userhome/latestdelivery.svg');
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: calc(100% / 3);
  border-radius: 11px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

}

.ourbestdeal .grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-self: auto;
  margin: auto;
}

.latestdelivery .location {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev,
.slick-next {

  width: 35px;
  height: 35px;

}

.slick-prev:before,
.slick-next:before {
  font-size: 32px;
  color: #E05A67;

}

.bestdealcarousal .slick-track {
  height: 261px;
}

.slick-dots {
  display: none !important;
}

.userhome .round-lightbrick-btn-outline {
  background-color: #fff;
  border: 1px solid #d60303;
  color: #d60303;
  border-radius: 50px;
  height: 33px;
  font-weight: 500;
}

.userhome .round-lightbrick-btn-outline:hover {
  background-color: #E05A67;
  color: #fff;

}

.userhome .round-lightbrick-btn-outline.solid {
  background-color: #E05A67;
  border: 1px solid #E05A67;
  color: #fff;
  border-radius: 50px;
  height: 33px;
  font-weight: 500;
}

.userhome .round-lightbrick-btn-outline:hover {
  background-color: #fff;
  border: 2px solid #E05A67;
  color: #E05A67;
  border-radius: 50px;
  height: 33px;
  font-weight: 600;
}

.userreview img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.userreview .slick-prev {
  left: -27px;
}


@media (max-width: 576px) {
  .pl-110 {
    padding-left: 0px;
  }

  .bg-latestdelivery {
    width: 100%;
  }

  .bestdealcarousal .item {
    width: max-content;
    max-width: 90%;
  }

  .bestdealcarousal .slick-slide {
    height: 300px;
  }

  .whychooseusimg {
    width: 238px;
  }

  .userreview .slick-prev {
    left: -18px;
  }

  .bestdealcarousal .slick-prev {
    left: -12px;

  }

  .bestdealcarousal .slick-next {
    right: -14px;
  }

  .whychooseus {
    margin-top: 10px;
}
.home-carousal .f-70{
  font-size: 20px;
}

.home-carousal .f-sm{
  font-size: 12px;

}

.home-carousal .lh-72{
  line-height: 28px;
}
.carousel-caption {
  right: 10% !important;
  bottom: 11px !important;
  left: 11% !important;

}

.home-carousal .shopnowbtn{
  font-size: 12px;
  height: 26px;
}

.home-carousal .desc{
  font-weight: 600;
}

.home-carousal .fa-chevron-right{
  font-size: 12px;
}

.carousel-control-prev-icon, .carousel-control-next-icon{
  width: 19px;
  height: 19px;
}

}