.link-item {
    position: relative;
    height: 40px;
    margin: 5px 0;
    transition: all 0.25s ease;
    background-color: transparent;
}

.navbarmain .postionsticky{
    position: sticky;
    top: 0;
    z-index: 2;
    background: #0B1426;
}

.link-item:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0px;
    left: -10px;
    background-color: #97262C;
    border-radius: 10px;
    opacity: 0;
    transition: all 0.25s ease;
}

.link-item:hover {
    background: #1D2637;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.link-item:hover:after {
    opacity: 1;
}

.link-item a:hover {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}

.top-navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    height: 70px;
    align-items: center;
}


.nav-search-inp {
    height: 33px;
    width: 275px;
    border: 1px solid #d5cccc;
    border-radius: 20px;
    padding: 5px 15px 5px 34px;
    font-size: 14px;
    color: #0B1426;
}

.search-i-position {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}

.search-i-position i {
    color: #62626294;
}

.nav-profile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}


.notification-dot-i {
    position: absolute;
    top: -13px;
    right: -4px;
}

@media(max-width:991px) {
    .top-navbar {
        background: rgba(224, 90, 103, 0.17);

    }


}

@media(max-width:576px) {
    .nav-search-inp {
        width: 200px;
    }

    .top-navbar {
        padding: 20px 20px;

    }
}