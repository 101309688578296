.ecommerce .box-decoration{
    background: linear-gradient(180deg, rgba(235, 161, 165, 0.77) 0%, #E05A67 100%);
border-radius: 12px;
width: calc(100% / 3);
color: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 130px;
line-height: 24px;
}

.ecommerce .box-decoration.white{
    background: linear-gradient(180deg, #E7E7E7 0%, rgba(241, 236, 236, 0.97) 100%);
    color: #0B1426;

}

.ecommerce .box-decoration .icon{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fcf8f6;
border-radius: 3px;
color: #E05A67;
}

.ecommerce .box-decoration .icon.black{
    background: #0B1426;
    color: #fff;

}

.ecommerce .topsellingimg{
    width: 60px;
    height: 45px;
     border-radius: 12px;
}

.ecommerce .number {
    background: #fae6ec;
    /* border-radius: 14px; */
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.ecommerce .border-bottom{
    padding: 15px 0px;
    border-bottom: 1px solid rgba(94, 92, 92, 0.45);
}
.region-table{
    width: 100%;
    overflow-x: scroll;
}

.rwd-table {
    margin: auto;
    min-width: 300px;
    max-width: 100%;
    border-collapse: collapse;
  }
  
  .rwd-table tr:first-child {
    border-top: none;
    background-color: #fff;
    color: black;
    font-weight: normal !important;
    font-size: 14px;
  }
  
  .rwd-table th {
    font-weight: 500 !important;
}
  .rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f9fc;
  }
  
  .rwd-table tr:nth-child(odd):not(:first-child) {
    /* background-color: #ebf3f9; */
    background-color: rgba(224, 90, 103, 0.11);
  }
  
  .rwd-table th {
    display: none;
  }
  
  .rwd-table td {
    display: flex;
  }
  
  .rwd-table td:first-child {
    margin-top: .5em;
  }
  
  .rwd-table td:last-child {
    margin-bottom: .5em;
  }
  
  .rwd-table td:before {
    content: attr(data-th) "";
    font-weight: bold;
    min-width: 140px;
    color: #000;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    margin-right: 10px;
    border-right: 1px solid rgba(167, 165, 165, 0.747);
  }
  
  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }
  
  .rwd-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .rwd-table tr {
    border-color: #bfbfbf;
    font-size: 13px;
    font-weight: 500;
}
  
  .rwd-table th,
  .rwd-table td {
    padding: .5em 1em;
  }
  @media screen and (max-width: 601px) {
    .rwd-table tr:nth-child(2) {
      border-top: none;
    }
  }
  @media screen and (min-width: 600px) {
    .rwd-table tr:hover:not(:first-child) {
      background-color: #fff;
    }
    .rwd-table td:before {
      display: none;
    }
    .rwd-table th,
    .rwd-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .rwd-table th:first-child,
    .rwd-table td:first-child {
      padding-left: 0;
    }
    .rwd-table th:last-child,
    .rwd-table td:last-child {
      padding-right: 0;
    }
    .rwd-table th,
    .rwd-table td {
      padding: 1em !important;
    }
  }
  
  
  /* THE END OF THE IMPORTANT STUFF */
  
  /* Basic Styling */


  @-webkit-keyframes leftRight {
    0%    { -webkit-transform: translateX(0)}
    25%   { -webkit-transform: translateX(-10px)}
    75%   { -webkit-transform: translateX(10px)}
    100%  { -webkit-transform: translateX(0)}
  }
  @keyframes leftRight {
    0%    { transform: translateX(0)}
    25%   { transform: translateX(-10px)}
    75%   { transform: translateX(10px)}
    100%  { transform: translateX(0)}
  }