.newinvoice .outlined-inp{
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 500;
    background-color: transparent;
}

.newinvoice .outlined-inp.w-165{
    width: 165px;
    height: 30px;
}

.newinvoice .outlined-inp.h-35{
    height: 35px;
}


.invoiceborder{
    border: 1px solid #D9D9D9;

}


.newinvoice .bg-lightbrick{
background-color: rgba(224, 90, 103, 0.11);

}