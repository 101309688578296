.tbl_scroll {
    overflow-x: scroll;
    transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

.sales table{
    width: max-content;
    transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

.sales thead th {
    width: auto;
}

.sales td, th {
    padding: 16px 24px;
    text-align: left;
}

.sales tr:nth-child(odd) {
  /* background-color: #ebf3f9; */
  background-color: rgba(224, 90, 103, 0.11);
}