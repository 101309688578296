.bg-image::before {
  /* background-image: url('../Registration/images/bg-verification.svg'); */
  background-image: url("../../images/bg-verification.svg");
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
}
