.overview .w-100div3{
    width: calc(100% / 3);
}

.overview .w-60-resp{
    width: 60%;
}

.overview .w-40-resp{
    width: 40%;
}


.w-60{
    width: 60%;
}

.zoom {
    transition: transform .5s; /* Animation */
  }
  
  .zoom:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.white-round-btn{
    background-color: #fff;
    color: #0B1426;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 10px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    text-decoration: none;
    font-weight: 600;
}

.white-round-btn:hover{
    border: 2px solid white;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}

.overflowhidden{
    overflow: hidden;
}


.delivery-img-position {
    width: 337px;
    position: absolute;
    top: 20px;
    left: -69px;
}

.overview .carousel-inner {
    border-radius: 11px !important;
}

.overview .total-img{
    transition: 0.3s all ease;
    width: 70px;
}

.overview .total-img:hover {
    transform: scale(1.1);
  }


.overview .carousel-caption {
    bottom: 1.25rem !important;
    right: 25% !important;

}



.overview .userimg{
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 5px;
}


.borderbottomdashed{
    border-bottom: 1px dashed rgba(128, 128, 128, 0.852);
}

.bordertopdashed{
    border-top: 1px dashed rgba(128, 128, 128, 0.852);
}

@media (max-width:991px){

.delivery-img-position {
    width: 216px;
    position: absolute;
    top: 54px;
    left: -123px;
}
}

@media (max-width:576px){
    .overview .w-100div3{
        width: 100%;
    }

    .overview .w-60-resp{
        width: 100%;

    }

    .overview .w-40-resp{
        width: 100%;
    }

    .delivery-img-position {
        width: 178px;
        top: 74px;
        left: -159px;
    
    }
}
