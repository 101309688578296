
.location .w-30{
    width: 30%;
}

.delivery-img{
    transform: scaleX(-1);

}


@media (max-width: 576px) {
    .location .w-30 {
      width: 45%;
    }
  }
  