.w-40{
    width: 40%;
}

.w-60{
    width: 60%;
}


.form__radio-input {
    display: none;
}
.form__radio-label {
    cursor: pointer;
    position: relative;
    padding-left: 2rem;
    font-size: 13px;
    font-weight: 500;
}
.form__radio-btn {
    display: inline-block;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 3px solid rgb(3, 26, 37);
    position: absolute;
    top: 3px;
    left: 0rem;
}
.form__radio-btn::after {
    content: "";
    display: block;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color:  rgb(3, 26, 37);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.form__radio-input:checked + .form__radio-label .form__radio-btn::after {
    opacity: 1;
}


@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

 .wrapper {
	 display: flex;
	 flex-direction: row;
	 /* flex-wrap: wrap; */
	 align-items: center;
	 justify-content: center;
}
 h1 {
	 font-family: inherit;
	 margin: 0 0 0.75em 0;
	 color: #728c8d;
	 text-align: center;
}
 .box {
	 display: block;
     min-width: 148px;
     height: 143px;
	 margin: 10px;
	 background-color: white;
	 border-radius: 5px;
	 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	 transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	 overflow: hidden;
}
 .upload-options {
	 position: relative;
	 height: 35px;
	 background-color: #E05A67;
	 cursor: pointer;
	 overflow: hidden;
	 text-align: center;
	 transition: background-color ease-in-out 150ms;
}
 .upload-options:hover {
	 background-color: #f14b5cd3;
}
 .upload-options input {
	 width: 0.1px;
	 height: 0.1px;
	 opacity: 0;
	 overflow: hidden;
	 position: absolute;
	 z-index: -1;
}
 .upload-options label {
	 display: flex;
	 align-items: center;
	 width: 100%;
	 height: 100%;
	 font-weight: 400;
	 text-overflow: ellipsis;
	 white-space: nowrap;
	 cursor: pointer;
	 overflow: hidden;
}
 .upload-options label::after {
	 content: 'add';
	 font-family: 'Material Icons';
	 position: absolute;
	 font-size: 2.0rem;
	 color: rgba(230, 230, 230, 1);
	 top: calc(50% - 1.5rem);
	 left: calc(50% - 1.0rem);
	 z-index: 0;
}
 .upload-options label span {
	 display: inline-block;
	 width: 50%;
	 height: 100%;
	 text-overflow: ellipsis;
	 white-space: nowrap;
	 overflow: hidden;
	 vertical-align: middle;
	 text-align: center;
}
 .upload-options label span:hover i.material-icons {
	 color: lightgray;
}
 .js--image-preview {
	 height: 108px;
	 width: 100%;
	 position: relative;
	 overflow: hidden;
	 /* background-image: url(''); */
	 background-color: white;
	 background-position: center center;
	 background-repeat: no-repeat;
	 background-size: cover;
}
 .js--image-preview::after {
	 content: "photo_size_select_actual";
	 font-family: 'Material Icons';
	 position: relative;
	 font-size: 4.5em;
	 color: rgba(230, 230, 230, 1);
	 top: calc(50% - 3rem);
	 left: calc(50% - 2.25rem);
	 z-index: 0;
}
 .js--image-preview.js--no-default::after {
	 display: none;
}
 .js--image-preview:nth-child(2) {
	 background-image: url('http://bastianandre.at/giphy.gif');
}
 i.material-icons {
	 transition: color 100ms ease-in-out;
	 font-size: 2.25em;
	 line-height: 55px;
	 color: white;
	 display: block;
}
 .drop {
	 display: block;
	 position: absolute;
	 background: rgba(95, 158, 160, 0.2);
	 border-radius: 100%;
	 transform: scale(0);
}
 .animate {
	 animation: ripple 0.4s linear;
}
 @keyframes ripple {
	 100% {
		 opacity: 0;
		 transform: scale(2.5);
	}
}

@media (max-width:991px){

.wrapper {
    flex-direction: column;

}
}