.bestoffers .grid-container {
        grid-template-columns: 1fr 1fr 1fr;

}


.offerposition {
        z-index: 1;
        top: -5px;
        /* opacity: 1; */
        right: -11px;
    }

    .bestoffers-items {
        width: 130px;
        height: 130px;
        border-radius: 50%;
    }

    .bestoffers .grid-item {
                width: 100%;
    }

    .multi-range-slider {
      border: none!important;
      border-radius: none;
      box-shadow: none!important;

  }

  .multi-range-slider .bar-inner {
    background-color: #E05A67 !important;
    box-shadow: none !important;
    border: none !important;

}
.multi-range-slider .thumb {
  background-color: #E05A67 !important;
}

.multi-range-slider .thumb::before {
  width: 15px !important;
  height: 15px !important;
  border: none !important;
  box-shadow: none !important;
  margin: -6px -12px !important;
}

.multi-range-slider .bar-right {
  background-color: #ececec !important;
  box-shadow:none!important;
}

.multi-range-slider .bar-left {
  background-color: #ececec !important;
  box-shadow: none !important;
  padding: 0px !important;


}

.multi-range-slider .bar {
  height: 4px !important;
}



.min-max{
  width: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 6px;
  border: 1px solid #E05A67;
  color: #E05A67;
  border-radius: 5px;
  margin: 5px;
  font-weight: 500;
}

.catoffer .banner-img{
  background-image: url('../../images/Catoffer/Artboard.jpg');
  /* background-size: auto; */


}

.catoffer input[type=checkbox] {

  border-radius: 50%;
}

.catoffer .grid-container {
  grid-template-columns: 1fr 1fr 1fr;

}