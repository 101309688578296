

.w-60{
    width: 60%;
}

.w-40{
    width: 40%;
}

.w-80{
    width: 80%;
}

.catalog .search-item{
    background: #F0F0F0;
    border: 1px solid #D2D2D2;
border-radius: 5px;
}

.catalog .transparent-inp{
    border: transparent;
    background-color: transparent;
    color: #292a2d;
    font-weight: 600;
}

.catalog .add-prod-link{
    padding: 6px 0px;
    text-decoration: none;
}

.catalog .add-prod-link:hover{
    text-decoration: none;

}

.catalog .transparent-inp:focus{
    border: none;
}

.catalog .catalog-table img{
  width: 64px;
  height: 44px;
    border-radius: 10px;
}

.catalog .slider{
    background: #D9D9D9;
    border-radius: 6px;
    color: #33363F;
}

.w-90{
    width: 90%;
}

.catalog .search-item-btn{
    border-left: 2px solid #cacaca;
    padding-left: 10px;
}

.catalog .instock{
    background: rgba(56, 226, 93, 0.14);
    border-radius: 3px;
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catalog .status {
  width: 94px;
}

.catalog .outstock{
    background: rgba(224, 90, 103, 0.38);
    border-radius: 3px;
    display: flex;
    width: 100px;
    height: 25px;
    align-items: center;
    justify-content: center;
}

.catalog .preorder{
    background: #CCD2E3;
    border-radius: 3px;
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}


table {
    width: 100%;
    border-collapse: collapse;
  }
  
  td,
  th {
    padding: 16px 24px;
    text-align: left;
  }
  
  thead tr {
    color: #000000;
    font-size: 13px;
  }
  
  thead th {
    width: 25%;
    font-weight: 500;

}
  
  tbody tr:nth-child(odd) {
    background-color: #fff;
    border-bottom: 1px solid #CCD2E3
    ;

  }
  
  tbody tr:nth-child(even) {
    background-color: #fff;
    border-bottom: 1px solid #CCD2E3;
  }
  
  input[type=checkbox],input[type=radio] {
    position: relative;
      border: 1px solid grey;
      border-radius: 6px;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 0 !important;
      vertical-align: text-top;
      height: 19px;
      width: 19px;
      -webkit-appearance: none;
    opacity: .5;
  }
  
  input[type=checkbox]:hover {
    opacity: 1;
  }
  
  input[type=checkbox]:checked, input[type=radio]:checked {
    background-color: #E05A67;
    border: 1px solid #E05A67;
    opacity: 1;
  }
  
  input[type=checkbox]:before, input[type=radio]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 6px;
    height: 12px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

  