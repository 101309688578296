.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: #868790;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: #E05A67;

  }

  .RSPBprogressBar {
    height: 4px;
    width: 50%;
    background-color: #868790;

  }

  .RSPBprogressBar .RSPBprogression {
    background: #E05A67;
  }

  .bb-dashed{
    border-bottom: 1px dashed rgba(152, 152, 152, 0.628);
  }

  .cart-table-item .prod-img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .checkout .qty-container .qty-btn-minus, .checkout .qty-container .qty-btn-plus {
    /* border: 1px solid #9b9b9b; */
    background-color: #E05A67;
    color: #fff;
    padding: 0px 0px;
    font-size: 10px;
    height: 24px;
    width: 24px;
    transition: 0.3s;

}

.checkout .qty-container .qty-btn-minus{
    border-radius: 7px 0px 0px 7px;
}

.checkout .qty-container .qty-btn-plus {
    border-radius: 0px 7px 7px 0px;

}

.checkout .qty-container .input-qty {
    /* border: 1px solid #9b9b9b; */
    border: none;
    max-width: 30px;
    height: 24px;
    border-radius: 0px;
}

.scootyman{
    background-image: url('../../images/Checkout/scootydriver.png');
    background-size: cover;
    height: 204px;
}


