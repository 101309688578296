.u-tooblbar{
    height: 60px;
}
.bg-whitealtbrick {
    background: #fff;
}
.bg-whitealtbrick:nth-of-type(odd){
    background: #dd7c862d;
}


.u-tooblbar .position-middle-r {
    top: 0px;
    transform: translateY(-50%);
    right: 7px;
}

.u-tooblbar .onhoverlightbrick{
    text-decoration: none;
    margin: 5px 0px;
    font-size: 14px;
}

.u-tooblbar .onhoverlightbrick:hover{
    color: #E05A67;
    font-weight: 600;

}

.u-tooblbar .onhoverlightbrick.logout:hover{
    color: #fff;

}


.u-tooblbar .popupdataimg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.u-tooblbar .popupboxsettings {
    right: 39px;
    top: 76px;
    z-index: 1;
}

#popupboxsettings{
    display: none;
}

#popupboxsettingmini{
    display: none;

}
#categoriespopupmini{
    display: none;
    top: 40px;
    left: -40px;
    z-index: 1;

}

#categoriespopupmini a{
    text-decoration: none;
}


#categoriespopupmini a:hover{
    text-decoration: none;
    color: #97262c;
    font-weight: 500;
}




.miniversion .popupboxsettings {
    right: -4%;
    z-index: 2;
    top: 42px;
}

.miniversion .popupboxsettings .popupdataimg{
    width: 40px;
}


.miniversion .popupboxsettings a{
 text-decoration: none;

}

.miniversion .popupboxsettings a:hover{
    text-decoration: none;
   color: #E05A67;
   font-weight: 500;
   }

.u-tooblbar .catitems{
    text-decoration: none;
}

.categoriespopuo{
    display: none;

}
.categoriespopuo {
    top: 51px;
    right: 0;
    z-index: 1;
    

}

.u-tooblbar .catitems:hover{
    text-decoration: none;
    color: #97262c;
    font-weight: 600;
}
.userpaneltopnav{
    .top-navbar {
        padding: 0px;
        box-shadow: none;
        height: 70px;
    }
}