.userorderpage .yourorders .prodimg{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.w-h-100{
    width: 100px;
    height: 100px;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
    padding: 5px;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 20px!important;
    height: 20px!important;
    margin-left: -10px!important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
    padding: 0.2em !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    /* left: 103% !important; */
    font-size: 0.9rem !important;
}

.vertical-timeline.vertical-timeline--two-columns {
    width: 100%;
}

